<template>
	<div class="maintenance">
		<SVGLogo height="36" class="center-logo" />
		<h1>Maintenance Mode</h1>
		<p>The system is currently under maintenance. Please try again later.</p>
		<button @click="retry" class="retry-button">Try Again</button>
	</div>
</template>

<script>
import SVGLogo from '@/assets/svg/logo.svg?inline';

export default {
	name: 'Maintenance',
	components: {
		SVGLogo
	},
	methods: {
		retry() {
			window.location.href = '/';
		},
	},
};
</script>

<style scoped>
h1 {
	margin-top: 30px;
}

.maintenance {
	text-align: center;
	margin-top: 50px;
}

.center-logo {
	display: block;
	margin: 0 auto;
}

p {
	margin-top: 20px;
}

.retry-button {
	margin-top: 20px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

.retry-button:hover {
	background-color: #0056b3;
}
</style>
